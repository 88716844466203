.pricing-annual {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1) !important;
}

/* .header contains two divs, stack them on top of each other */

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    font-family: DMSansRegular, sans-serif;
    margin-top: 2rem;
}

.main-header {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
}

.sub-header {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: DMSansMedium, sans-serif;
    color: #333;
    padding-top: 1rem;
}

.type-annual {
    display: flex;
    background-color: #526fff;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    width: 170px;
}

.type-monthly {
    display: flex;
    background-color: #f1f5f9;
    color: #526fff;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 20px;
    width: 90px;
}

.checkmark {
    transform: translate(-1px, 5px);
    margin-right: 0.7rem;
}

.pricing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pricing-container {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 1rem;
    margin-bottom: 4rem;
}

.pricing-annual {
    margin-left: 1.5rem;
}

.pricing-annual:hover {
    box-shadow: 0px 0px 10px rgba(166, 0, 255, 0.349) !important;
}

.pricing-monthly {
    margin-right: 1.5rem;
}

@media (max-width: 768px) {
    .pricing-container {
        flex-direction: column;
    }

    .pricing-annual {
        margin-left: 0;
        margin-top: 2rem;
    }

    .pricing-monthly {
        margin-right: 0;
    }
}
